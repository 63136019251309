import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Nav, StyledHeader, Logo } from "../styles/Navbar.styled"
import { getImage } from "gatsby-plugin-image"

const NavBar = () => {
  const data = useStaticQuery(graphql`
    query Logo {
      contentfulAsset(title: { eq: "Memoji" }) {
        gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED, width: 84)
        description
      }
    }
  `)
  const image = data.contentfulAsset
  return (
    <StyledHeader>
      <Nav>
        <Link to="/">
          <div>
            <Logo align-items="logo" image={getImage(image)} />
            <text>Home</text>
          </div>
        </Link>
        <ul>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </Nav>
    </StyledHeader>
  )
}

export default NavBar
