import React from "react";
import NavBar from "./Navbar";
import GlobalStyles from "../styles/Global";
import Seo from "./Seo";

// const Layout = ( logo, children ) => {
const Layout = ({ children, title } ) => {
  console.log('layout-page')
  console.log(children)
  return (
    <div>
      <GlobalStyles/>
      <Seo title={title} />
      <NavBar />
      <main>{children}</main>
    </div>
  )
}

export default Layout