import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const StyledHeader = styled.header`
  // background-color: ${({ theme }) => theme.colors.header};
  background-color: lightblue;
  padding: 5px 0;
  margin-bottom: 5px;
`

export const Nav = styled.nav`
  display: flex;
  height: 60px;
  //flex: 1000px auto;
  //align-items: center;
  //justify-content: flex-start;
  //margin-bottom: 2rem;
  list-style-type: none;
  vertical-align: central;
  justify-content: space-between;
  max-width: var(--size-max-width);
  align-items: center;
  margin: 0 auto;
  //vertical-align: central;

  //ul {
  //  list-style-type: none;
  //}

  div {
      vertical-align: middle;
  }
  
  li {
    height: 10rem;
    display: inline;
    margin: 1rem;
  }

  a {
    //text-align: justify;
    margin: 1rem;
    //image {
    //  vertical-align: middle;
    //  height: 10rem;
    //  display: inline;
    //  margin: 1rem;
    //}
    text {
      //vertical-align: bottom;
      height: 10rem;
      display: inline;
      margin: 1rem;
    }
  }
  
  a:hover {
    background-color: cornflowerblue;
    text-decoration: none;
  }

  // @media (max-width: ${({ theme }) => theme.mobile}) {
  //   flex-direction: column;
  // }
`

export const Logo = styled(GatsbyImage)`
  Img {
    height: 60px;
    width: 60px;
    //border: 1px solid whitesmoke;
  }

  Img:hover {
    border-radius: 120px;
    //background-color: antiquewhite;
    //box-shadow: 0 0 3px 1px red;
  }

  //   @media (max-width: ${({ theme }) => theme.mobile}) {
  //     margin-bottom: 40px;
  //   }
`

// export const Image = styled.img`
//   width: 375px;
//   margin-left: 40px;
//   @media (max-width: ${({ theme }) => theme.mobile}) {
//     margin: 40px 0 30px;
//   }
// `
